import backgroundImage from "@/images/errors/background-error.webp";
import Image from "next/image";
import Title from "../atoms/Title";
import Text from "../atoms/Text";
import Button from "../atoms/Button";
import Link from "next/link";
import imageErrors from "@/images/errors/image-errors.webp";

const ContentError = ({ errorType = "", t }) => {
  return (
    <div>
      <div className="h-screen lg:block relative hidden">
        <Image src={backgroundImage} className="h-full w-full" />
        <div className="absolute top-0 z-10 flex justify-center items-center mt-64">
          <div className="base-container grid grid-cols-12">
            <div className="col-span-8 col-start-4 grid grid-cols-12 h-[45vh] relative">
              <div className="col-span-7 bg-white pr-2 grid grid-cols-7">
                <div className="col-span-7 mt-[10vh] ml-6">
                  <Title
                    tag="h1"
                    className="hero-title lg:text-[50px] lg:leading-[55px] text-center text-main-blue"
                  >
                    {errorType === "404" ? "Error 404" : "Error 500"}
                  </Title>
                  <Text className="hero-title lg:text-[28px] lg:leading-[32px] text-center text-main-grey mt-4">
                    {errorType === "404"
                      ? t("common:error404.text")
                      : t("common:error500.text")}
                  </Text>
                  <div className="flex justify-center mt-6">
                    <Link href="/">
                      <Button className="hero-link">
                        {errorType === "404"
                          ? t("common:error404.button")
                          : t("common:error500.button")}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-span-5 relative grid grid-cols-6 h-full relative">
                <div className="col-span-1 absolute bg-white w-[5vw] h-full"></div>
                <div className="col-span-6 relative flex justify-start items-center h-full">
                  <div className="absolute">
                    <Image src={imageErrors} className="h-[35vh] w-[33vh]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full h-screen max-h-screen absolute top-0"
          style={{ backgroundColor: "rgba(0, 32, 92, 0.8)" }}
        />
      </div>
      <div className="lg:hidden h-screen relative">
        <div className="w-full h-screen flex justify-center items-center">
          <Image src={backgroundImage} className="h-full w-full object-cover" />
        </div>
        <div className="absolute top-0 z-10 w-full mt-[150px]">
          <div className="base-container">
            <div className="grid grid-cols-12 relative">
              <div className="col-span-10 col-start-2 relative z-10 flex justify-center items-center">
                <div className="max-h-[342px] max-w-[305px]">
                  <Image src={imageErrors} />
                </div>
              </div>
              <div className="col-span-12 bg-white absolute bottom-[-250px] md:bottom-[-180px] w-full pt-32">
                <Title
                  tag="h2"
                  className="hero-title text-[30px] leading-[40px] text-center text-main-blue"
                >
                  {errorType === "404" ? "Error 404" : "Error 500"}
                </Title>
                <Text className="hero-title text-[20px] leading-[32px] text-center text-main-grey mt-4 mx-8">
                  {errorType === "404"
                    ? t("common:error404.text")
                    : t("common:error500.text")}
                </Text>
                <div className="flex justify-center mt-4 mb-8">
                  <Link href={"/"}>
                    <Button className="hero-link">
                      {errorType === "404"
                        ? t("common:error404.button")
                        : t("common:error500.button")}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full h-screen max-h-screen absolute  top-0"
          style={{ backgroundColor: "rgba(0, 32, 92, 0.8)" }}
        />
      </div>
    </div>
  );
};

export default ContentError;
