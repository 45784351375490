import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { useTranslation } from 'next-i18next';
import Head from "next/head"
import NavBar from "@/components/templates/NavBar";
import Footer from "@/components/templates/Footer";
import ContentError from "@/components/templates/ContentError";


export default function Custom404() {
    const { t } = useTranslation('common', 'routes')
    return (
        <>
            <Head>
                <title>Golden Parnassus | Error 404</title>
                <link rel="icon" href="/favicon.ico" />
                <meta name="robots" content="noindex" />
            </Head>
            <NavBar
                theme="navbar-aqua"
                classes={{
                    locale: "hidden"
                }}
            />
            <ContentError
                errorType="404"
                t={t}
            />
            <div className="relative bg-white ">
                <Footer />
            </div>
        </>
    )
}


export async function getStaticProps({ locale }) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common', 'routes'])),
        },
    };
}